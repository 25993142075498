<style lang="less" scoped>
/deep/ .el-table__row > td {
  border: none;
}
/deep/ .el-table::before {
  //去掉最下面的那一条线
  height: 0px;
}
.el-button {
  font-size: 14px;
}
.el-button--success {
  background-color: #24e0d0;
  border: #24e0d0;
  font-weight: bold;
}
.el-button--primary {
  background-color: #0090f0;
  border: #0090f0;
  font-weight: bold;
}
.el-button--warning {
  background-color: #e56c00;
  border: #e56c00;
  font-weight: bold;
}
.content {
  padding: 25px;
  background-color: #fff;
}
.btn {
  width: 100px;
  height: 36px;
  border-radius: 18px;
}
img {
  width: 20px;
  height: 18px;
  vertical-align: middle;
  margin-right: 3px;
}
.el-button--text {
  color: #0090f0;
}
</style>
<template>
  <div class="content">
    <el-table
      :data="tableData"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      default-expand-all
      :header-cell-style="headerStyle"
      :header-row-style="{ height: '66px' }"
      :cell-style="cellStyle"
      :tree-props="{ children: 'son', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        prop="subsection"
        label="列表"
        width="800"
      ></el-table-column>
      <!-- <el-table-column prop="address" label width="120">
        <template slot-scope="scope">
          <img src="../../../assets/img/tiku/icon_3_13.png" />
          <el-button type="text" @click="handleDelete1(scope.$index, scope.row)"
            >考点解析</el-button
          >
        </template>
      </el-table-column>-->
      <el-table-column
        prop="rate"
        label="正确率"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="content"
        label="已做/总值"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column width="150" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.who"
            class="btn"
            size="small"
            type="primary"
            @click="handleDelete2(scope.$index, scope.row)"
            >开始做题</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: ["tableData"],
  data() {
    return {
      user_id: JSON.parse(window.localStorage.getItem("userInfo"))
        ? JSON.parse(window.localStorage.getItem("userInfo")).id
        : "",
    };
  },
  mounted() {},
  methods: {
    headerStyle() {
      return {
        "font-size": "16px",
        height: "66px",
        padding: "0",
        color: "#333333",
        "font-weight": "400",
        "background-color": "#F8F8F8",
        border: "none",
      };
    },
    cellStyle() {
      return {
        "font-size": "15px",
        height: "66px",
        padding: "0",
      };
    },
    handleDelete1() {},
    handleDelete2(i, row) {
      // console.log(i, row);
      // console.log(row)
      this.$router.push({
        path: "/exam",
        query: { c_e_id: row.exam_id, user_id: this.user_id },
      });
    },
    load(tree, treeNode, resolve) {},
  },
};
</script>